import {
  IBoostTag,
  IPushPayment,
  ITransactionWithProfile
} from 'models/models';
import {
  getDetailsByTxnRefUrl,
  getDetailsByBoostTagUrl,
  getTransactionByAnonymousUrl
} from '../constants/urls';
import FetchAPI from './FetchAPI';
import PostAPI from './PostAPI';

const TerminalAPI = {
  getDetailsByTxnRef: (transactionRef: string) => async () => {
    const response: ITransactionWithProfile = await FetchAPI.get(
      getDetailsByTxnRefUrl(transactionRef)
    );
    return response;
  },
  getDetailsByBoostTag: (boostTag: string) => async () => {
    const response: IBoostTag = await FetchAPI.get(
      getDetailsByBoostTagUrl(boostTag)
    );
    return response;
  },
  transactionByAnonymous: async (data: {
    amount: number;
    notes?: string;
    currency: string;
    boostTag: string;
  }) => {
    const response: IPushPayment = await PostAPI.post(
      getTransactionByAnonymousUrl(),
      { ...data }
    );
    return response;
  }
};

export default TerminalAPI;
