import styled from 'styled-components';

import { colours } from 'constants/colours';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 87%;
  margin-bottom: 10px;
`;

const Label = styled.p`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colours.greyText};
  opacity: 0.5;
`;

const Merchant = styled.p`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: ${colours.blackText};
`;

const Price = styled.p`
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  text-align: right;
  color: ${colours.greyText};
`;

const DetailsText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
`;

const PayeeCotainer = styled.div`
  padding: 16px 18px;
  width: 92%;
  background-color: ${colours.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Details = styled.span`
  font-weight: 500;
`;

const PaymentSummary = ({
  price = '£0.00',
  payeeName = 'Pay With Boost',
  merchantName = 'Pay With Boost',
  accountDetails = '',
  paymntRef = '',
  date = ''
}) => (
  <Container>
    <ItemDetailsContainer>
      <div>
        <Label>Merchant</Label>
        <Merchant>{merchantName}</Merchant>
      </div>
      <div>
        <Label>Payment Total</Label>
        <Price>{price}</Price>
      </div>
    </ItemDetailsContainer>
    <PayeeCotainer>
      {date.length !== 0 && (
        <DetailsText>
          Date{' '}
          <Details>
            {new Date(date + 'Z').toLocaleDateString('en-US', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </Details>
        </DetailsText>
      )}
      <DetailsText>
        Payee name: <Details>{payeeName}</Details>
      </DetailsText>
      <DetailsText>
        Account details: <Details>{accountDetails}</Details>
      </DetailsText>
      {paymntRef && (
        <DetailsText>
          Payment ref: <Details>{paymntRef}</Details>
        </DetailsText>
      )}
    </PayeeCotainer>
  </Container>
);

export default PaymentSummary;
