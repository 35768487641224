import styled, { css } from 'styled-components';
import { colours } from 'constants/colours';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Container = styled.div`
  margin: 16px;
  padding: 16px;
  border-radius: 10px;
  background-color: ${colours.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 12px;
  margin: 8px 16px 16px;
  background: ${colours.button};
  color: ${colours.background};

  border: none;
  border-radius: 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.3px;

  cursor: pointer;
`;

export const Label = styled.p<{ warning?: true }>`
  margin: 5px 0px;
  color: ${(props) => props.warning ? colours.button : colours.darkGrey};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
`;

export const Input = styled.input<{ withAsset?: true }>`
  width: 100%;
  height: 50px;
  padding: 13px;
  background: ${colours.white};
  color: ${colours.charcoal};
  border: 1px solid;
  border-color: ${colours.silverGrey};;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  
  &:focus {
    color: ${colours.charcoal};;
    border-color: ${colours.darkGrey};;
    outline: none;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colours.darkGrey};
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${colours.darkGrey};
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: ${colours.darkGrey};
  }
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type=number] {
    -moz-appearance: textfield;
  }

  ${props => 
    props.withAsset && 
    css`
      background-image:url("data:image/svg+xml;utf8,
      <svg xmlns='http://www.w3.org/2000/svg' version='1.1'
          height='50px' width='50px'>
        <text x='0' y='31' fill='333333' font-size='18' font-family='Poppins'>£</text>
      </svg>");
      background-position: left 18px center;
      background-repeat: no-repeat;
      padding-left: 30px;
      text-indent: 0px;
    `};
`;
