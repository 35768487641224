const URL = process.env.REACT_APP_URL;
export const AUTH_PAYMENT_URL = URL + '/v2/transactions/authorize';
export const getBanks = (type: string) =>
  URL + `/v2/financial-institutions?type=${type}`;
export const markedAsDisplayed = (transactionRef: string) =>
  URL + `/v2/transactions/${transactionRef}/mark-as-displayed`;
export const getDetailsByTxnRefUrl = (transactionRef: string) =>
  URL + `/v2/transactions/${transactionRef}`;
export const getDetailsByBoostTagUrl = (boostTag: string) =>
  URL + `/v2/boost-tags/${boostTag}`;
export const getTransactionByAnonymousUrl = () =>
  URL + '/v2/transactions/by-anonymous';
