import styled from 'styled-components';

import { IBank } from 'models/models';
import Avatar from 'components/Avatar';
import rightArrowIcon from 'assets/right-arrow.svg';
import { colours } from 'constants/colours';

interface IBankListItem {
  bank: IBank;
  isLastItem: boolean;
  avatarIndex: number;
  onClickBank(bankId: string): void;
}

interface IContainer {
  isLastItem?: boolean;
}

const Container = styled.div<IContainer>`
  /* background-color: red; */
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 72px;
  /* width: 420px; */
  padding: 0 24px;
  cursor: pointer;
  border-bottom: ${(props) => !props.isLastItem && `1px solid ${colours.grey}`};
  @media (max-width: 480px) {
    /* width: 340px; */
  }
  @media (max-width: 380px) {
    /* width: 290px; */
    padding-left: 12px;
  }
`;

const BankName = styled.span`
  margin-left: 13px;
  color: ${colours.blackText};
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
`;

const Image = styled.img`
  position: absolute;
  right: 16px;
  @media (max-width: 480px) {
    right: 16px;
  }
`;

const BankListItem = ({ bank, isLastItem, onClickBank }: IBankListItem) => (
  <Container isLastItem={isLastItem} onClick={() => onClickBank(bank.id)}>
    <Avatar src={bank.iconURL} size={'3rem'} />
    <BankName>{bank.name}</BankName>
    <Image src={rightArrowIcon} alt="right-arrow" />
  </Container>
);

export default BankListItem;
