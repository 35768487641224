export const colours = {
  grey: '#ededf7',
  navy: '#495576',
  white: '#ffffff',
  black: '#000000',
  charcoal: '#333333',
  greyText: '#333',
  button: '#f72585',
  primary: '#3a529c',
  offWhite: '#f4f5f9',
  blackText: '#11142D',
  secondary: '#1ca9f0',
  scrollbar: '#f1f1f1',
  background: '#fdf3f3',
  blueSeparator: '#4361EE',
  listSeparator: '#e5e5e575',
  darkGrey: '#828282',
  silverGrey: '#C4C4C4'
};
