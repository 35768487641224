import PayWithBoostInfo from './payWithBoostInfo';
import * as S from './styles';

const Header = () => (
  <S.ImageContainer>
    <PayWithBoostInfo />
    <S.Divider />
  </S.ImageContainer>
);

export default Header;