import { useState, useEffect, useCallback } from 'react';

import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import Bowser from 'bowser';
import Lottie from 'lottie-react';

import Header from 'components/header';
import TerminalAPI from 'services/TerminalAPI';
import PaymentSummary from 'components/paymentSummary';
import { AccountType } from 'models/models';

import badgeIOS from '../../assets/badge-ios.svg';
import badgeAndroid from '../../assets/badge-android.svg';
import twitter from '../../assets/twitter.svg';
import boost from '../../assets/boost-icon.svg';
import facebook from '../../assets/facebook.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import SuccessAnimation from '../../assets/success-tick.json';
import * as FirestoreService from '../../utils/firebase';

import * as S from './styles';
import { colours } from 'constants/colours';

const SuccessPage = () => {
  const history = useHistory();
  const browser = Bowser.getParser(window.navigator.userAgent);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [date, setDate] = useState<string>('');
  const merchant = query.has('merchant') ? query.get('merchant') : undefined;

  const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);

  const profile = query.has('profile') ? query.get('profile') : undefined;

  const transactionsRef = query.has('transactionRef')
    ? query.get('transactionRef')
    : undefined;

  const organization = query.has('organization')
    ? query.get('organization')
    : undefined;

  const terminal =
    query.has('terminal') && query.get('terminal') !== null
      ? query.get('terminal')!
      : '';

  const amount =
    query.has('amount') && query.get('amount') !== null
      ? parseInt(query.get('amount')!)
      : 0;

  const { data: transaction } = useQuery(
    'linkData',
    TerminalAPI.getDetailsByTxnRef(transactionsRef!),
    {
      // The query will not execute until the userId exists
      enabled: !!transactionsRef
    }
  );

  const handleError = useCallback(
    () =>
      history.push(
        `status/failed?profile=${profile}&transactionRef=${transactionsRef}&amount=${amount}&currency${'GBP'}`
      ),
    [history, profile, transactionsRef, amount]
  );

  useEffect(() => {
    FirestoreService.authenticateAnonymously()
      .then(() => {
        FirestoreService.streamStates(
          profile,
          transactionsRef,
          (data2) => {
            data2.docs.forEach((element) => {
              if (element.id === 'AUTHORIZED') {
                if (element.data().result === 'SUCCESS') {
                  setDate(element.data().createdAt);
                  FirestoreService.recordEvent('Consumer-Payment_success', {});
                  setTransactionSuccess(true);
                } else {
                  handleError();
                }
              }
            });
          },
          () => {}
        );
      })
      .catch(() => {});
  }, [
    search,
    merchant,
    terminal,
    handleError,
    organization,
    transactionsRef,
    profile
  ]);

  return (
    <S.Container>
      <Header />
      {transactionSuccess ? (
        <S.SuccessMessage>
          <S.AnimationWrapper>
            <Lottie animationData={SuccessAnimation} loop={true} />
          </S.AnimationWrapper>
          <S.SuccessText>Payment successful</S.SuccessText>
        </S.SuccessMessage>
      ) : <S.Spacer></S.Spacer>}

      <S.PaymentSummaryWrapper>
        <PaymentSummary
          isLoading={!transactionSuccess}
          date={
            date.length > 0
              ? new Date(date + 'Z').toLocaleDateString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
              })
              : ''
          }
          name={transaction?.profile?.name}
          paymentRef={transaction?.reference}
          price={`£${((transaction?.amount ?? 0) / 100).toFixed(2)}`}
          accountDetails={`${
            transaction?.profile?.bankAccount.sortCode || ''
          }  ${transaction?.profile?.bankAccount.accountNumber || ''}`}
          notes={transaction?.notes}
          boostTag={
            transaction?.profile?.defaultBoostTag?.tag ||
            transaction?.profile.boostTags?.[0]?.tag
          }
          isConsumer={transaction?.profile?.type === AccountType.personal}
          outline={colours.blueSeparator}
          spacer={20}
        />
      </S.PaymentSummaryWrapper>
      <S.TextBold>Download Boost today! </S.TextBold>
      <S.Description>
        Request money from anyone, without sharing your bank details.{' '}
      </S.Description>
      <S.Row>
        {(browser.getPlatform().type === 'desktop' || browser.getOSName() !== 'Android') && (
          <a href="https://apps.apple.com/gb/app/pay-with-boost/id1623811191">
            <img src={badgeIOS} alt="" />
          </a>
        )}
        {(browser.getPlatform().type === 'desktop' || browser.getOSName() !== 'iOS')  && (
          <a href="https://play.google.com/store/apps/details?id=com.pwb.merchant">
            <img src={badgeAndroid} alt="" />
          </a>
        )}
      </S.Row>
      <a href="https://www.paywithboost.com" target="_blank" rel="noreferrer" onClick={() => {
        FirestoreService.recordEvent('Consumer-External_Link_pressed', {
          link_name: 'boost_site'
        });
      }}>
        <img width="170" src={boost} alt="" />
      </a>
      <S.SocialsContainer>
        <a href="https://twitter.com/PayWithBoost" onClick={() => {
          FirestoreService.recordEvent('Consumer-External_Link_pressed', {
            link_name: 'twitter'
          });
        }}>
          <img src={twitter} alt="twitter" />
        </a>
        <a href="https://www.instagram.com/paywithboost" onClick={() => {
          FirestoreService.recordEvent('Consumer-External_Link_pressed', {
            link_name: 'instagram'
          });
        }}>
          <img src={instagram} alt="instagram" />
        </a>
        <a href="https://www.linkedin.com/company/paywithboost" onClick={() => {
          FirestoreService.recordEvent('Consumer-External_Link_pressed', {
            link_name: 'linkedin'
          });
        }}>
          <img src={linkedin} alt="linkedin" />
        </a>
        <a href="https://www.facebook.com/paywithboost" onClick={() => {
          FirestoreService.recordEvent('Consumer-External_Link_pressed', {
            link_name: 'facebook'
          });
        }}>
          <img src={facebook} alt="facebook" />
        </a>
      </S.SocialsContainer>
      <S.BottomText>Follow us and share your support.</S.BottomText>
    </S.Container>
  );
};

export default SuccessPage;
