import { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { BanksType, IBank } from 'models/models';
import Loader from 'components/Loader';
import BanksAPI from 'services/BanksAPI';
import { recordEvent } from 'utils/firebase';
import AuthPaymentAPI from '../../services/AuthPaymentAPI';
import BankListItem from './BankListItem';
import { QueryStatus } from 'constants/queryStatus';

import * as S from './styles';

const Banks = ({ isLoading, data, status, onClick }) => {
  if (isLoading) return <Loader text={'Payment in progress...'} />;
  if (status === QueryStatus.LOADING) return <Loader text={'Getting banks...'} />;
  if (status === QueryStatus.ERROR) return <span>Something went wrong </span>;
  if (data?.length === 0) return <span>No banks found </span>;

  return data?.map((bank: IBank, index) => (
    <li key={bank.id}>
      <BankListItem
        bank={bank}
        isLastItem={false}
        onClickBank={onClick}
        avatarIndex={index % 2}
      />
    </li>
  ));
};

const BanksList = ({ transactionId, amount, terminal }) => {
  const history = useHistory();
  const [type, setType] = useState(BanksType.personal);
  const { status: personalStatus, data: personalData } = useQuery('personalBanksData', () => BanksAPI.getBanks(BanksType.personal));
  const { status: businessStatus, data: businessData } = useQuery('businessBanksData', () => BanksAPI.getBanks(BanksType.business));
  const data = type === BanksType.personal ? personalData : businessData;
  const status = type === BanksType.personal ? personalStatus : businessStatus;

  const mutation = useMutation(AuthPaymentAPI.authPayment, {
    onSuccess: (mutationData) => {
      recordEvent('Consumer-Payment_initiated', {});
      window.location.href = mutationData.callbackUrl;
    },
    onError: (error) => {
      recordEvent('Consumer-Payment_error', error);
      if (!terminal) {
        history.push(`/failed?terminal=${terminal}&amount=${amount}`);
      }
    },
  });

  const onClick = useCallback(
    (bankId: string) => {
      recordEvent('Consumer-Selected_bank', {
        Bank_name: bankId,
      });
      mutation.mutate({ bankId, transactionId });
    },
    [mutation, transactionId],
  );

  return (
    <S.Container isLoaded={Boolean(data && data.length > 0 && !mutation.isLoading)}>
      {data && !mutation.isLoading && (
        <>
          <S.TopSeparator />
          <S.Paragraph>Select your bank to make a payment.</S.Paragraph>
          <S.TabContainer>
            <S.Tab selected={type === BanksType.personal} onClick={() => setType(BanksType.personal)}>Personal</S.Tab>
            <S.Tab right selected={type === BanksType.business} onClick={() => setType(BanksType.business)}>Business</S.Tab>
          </S.TabContainer>
        </>
      )}

      <S.List isLoaded={Boolean(data && data.length > 0 && !mutation.isLoading)}>
        {mutation.isError && <S.ErrorSpan>Something went wrong </S.ErrorSpan>}
        <Banks 
          onClick={onClick}
          data={data}
          isLoading={mutation.isLoading}
          status={status}
        />
      </S.List>
      {data && data.length > 0 && !mutation.isLoading && (
        <S.Link clickable href="https://tally.so/r/w818vA" rel="noreferrer" target="_blank">
          Don&apos;t see your bank?
        </S.Link>
      )}
    </S.Container>
  );
};

export default BanksList;
