import { useState } from 'react';
import Collapsible from 'react-collapsible';

import { InfoContainer, InfoDescription, InfoRegulations, Strong, Row, Column } from 'components/styles-shared';
import { recordEvent } from 'utils/firebase';

import info from '../../assets/info.svg';
import infoGrey from '../../assets/grey-info.svg';
import yappily from '../../assets/yapily-connect.svg';
import minusGrey from '../../assets/remove-circle-outline-grey.svg';
import minus from '../../assets/remove-circle-outline.svg';

const Info = () => {
  const [openData, setOpenData] = useState<boolean>(false);
  const [openSecure, setOpenSecure] = useState<boolean>(false);
  const [openAuth, setOpenAuth] = useState<boolean>(false);
  const [openAbout, setOpenAbout] = useState<boolean>(false);
  const [openMainKey, setOpenMainKey] = useState<boolean>(false);
  return (
    <InfoContainer>
      <Collapsible
        triggerClassName="parentCollapsibleTrigger"
        contentInnerClassName="parentCollapsibleInner"
        contentOuterClassName="parentCollapsibleOuter"
        triggerOpenedClassName="parentCollapsibleTrigger"
        onOpen={() => {setOpenMainKey(true); recordEvent('Consumer-Yapily_pressed', {});}}
        onClose={() => {setOpenMainKey(false); recordEvent('Consumer-Yapily_pressed', {});}}
        trigger={
          <Column>
            <Row>
              <img src={yappily} />
              <img width="17" height="17" src={openMainKey ? minusGrey : infoGrey} />
            </Row>
            <InfoRegulations>
              Regulated by the Financial Conduct Authority (FCA) as an Authorised
              Payment Institution with reference number{' '}
              <a
                onClick={() => recordEvent('Consumer-External_Link_pressed', { link_name: 'fca' })}
                target="_blank"
                rel="noreferrer"
                href="https://register.fca.org.uk/s/firm?id=0010X00004J9MH9QAN">
                827001
              </a>
              .
            </InfoRegulations>
          </Column>
        }>
      <Collapsible
        key={1}
        triggerClassName="childCollapsibleTrigger"
        triggerOpenedClassName="childCollapsibleTrigger"
        onOpen={() => {setOpenData(true);}}
        onClose={() => {setOpenData(false);}}
        trigger={
          <>
            {'Data Sharing'}
            <img width="17" height="17" src={openData ? minus : info} />
          </>
        }>
        <InfoDescription>
          Yapily Connect will retrieve bank data needed to facilitate this
          payment based on your request and provide this information to Boost.
        </InfoDescription>
      </Collapsible>
      <Collapsible
        key={2}
        triggerClassName="childCollapsibleTrigger"
        triggerOpenedClassName="childCollapsibleTrigger"
        onOpen={() => { setOpenSecure(true); }}
        onClose={() => { setOpenSecure(false); }}
        trigger={
          <>
            {'Secure connection'}
            <img width="17" height="17" src={openSecure ? minus : info} />
          </>
        }>
        <InfoDescription>
          Data is securely accessed in read-only format and only for the
          purposes of this payment request. This request is a one off, you will
          not receive any other requests from SafeConnect for this payment.
        </InfoDescription>
      </Collapsible>
      <Collapsible
        key={3}
        triggerClassName="childCollapsibleTrigger"
        triggerOpenedClassName="childCollapsibleTrigger"
        onOpen={() => { setOpenAuth(true);}}
        onClose={() => { setOpenAuth(false);}}
        trigger={
          <>
            {'FCA Authorisation'}
            <img width="17" height="17" src={openAuth ? minus : info} />
          </>
        }>
        <InfoDescription>
          Yapily Connect Ltd is authorised and regulated by the Financial
          Conduct Authority under the Payment Service Regulations 2017 [
          <a
            onClick={() => recordEvent('Consumer-External_Link_pressed', { link_name: 'fca' })}
            target="_blank"
            rel="noreferrer"
            href="https://register.fca.org.uk/s/firm?id=0010X00004J9MH9QAN">
            827001
          </a>
          ] for the provision of Account Information and Payment Initiation
          services.
        </InfoDescription>
      </Collapsible>
      <Collapsible
        key={4}
        triggerClassName="childCollapsibleTrigger"
        triggerOpenedClassName="childCollapsibleTrigger"
        onOpen={() => { setOpenAbout(true);}}
        onClose={() => { setOpenAbout(false);}}
        trigger={
          <>
            {'About the access'}
            <img width="17" height="17" src={openAbout ? minus : info} />
          </>
        }>
        <InfoDescription>
          This consent request is a one-off, you will not receive additional
          requests once completed.
          <br />
          <br />
          Please read Yapily Connect{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://yapi.ly/MwMo"
            onClick={() => {
              recordEvent('Consumer-External_Link_pressed', {
                link_name: 'yapily_terms'
              });
            } }>
            PIS - Terms &amp; Conditions
          </a>
          .<br />
          <br />
        </InfoDescription>
      </Collapsible>
      <InfoDescription>
        To easily set up payments from our bank to
        <Strong> Boost</Strong>,. we are about to securely re-direct you to your
        bank where you will be asked to confirm the payment via Yapily Connect,
        an FCA regulated payment initiation provider for <Strong>Boost</Strong>.
        Yapily Connect will share these details with your bank, where you will
        then be asked to confirm the following payment setup.
      </InfoDescription>
    </Collapsible>
    </InfoContainer>
  );
};

export default Info;
